import { memo } from 'react';
import loading from 'src/resources/img/loading.gif';
import cn from './index.module.scss';

function Loading() {
  return (
    <div>
      <img src={loading} className={cn.loading} alt='' />
    </div>
  );
}

export default memo(Loading);
