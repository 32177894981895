import { lazy } from "react";
import { RouteObject, useRoutes } from "react-router-dom";

// 快速导入工具函数
const lazyLoad = (moduleName: string) => {
  const Module = lazy(() => import(`pages/${moduleName}`));
  return <Module />;
};

const defaultRoutes: Array<RouteObject> = [
  {
    path: "/",
    element: lazyLoad("Home"),
  },
  {
    path: "/list/:name",
    element: lazyLoad("List"),
  }
];


const Router: React.FC = () => {
  return useRoutes(defaultRoutes);
};

export default Router;
