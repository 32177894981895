import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Loading from 'src/components/Loading';
import './index.css';
import Router from './router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
      <Router />
    </Suspense>
  </BrowserRouter>
);
